::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  background-color: rgb(37, 37, 37);
}
::-webkit-scrollbar-thumb {
  background-color: #575757;
}

html {
  margin: 0 10px;
  font-family: "Montserrat", "Roboto", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: auto;
  background-color: #111111;
  color: #fff;
}

body {
  font-family: "Montserrat";
  height: 100%;
  width: auto;
  max-width: 1000px;
  margin: 0 auto;
}

header {
  margin: 20px 0;
}

footer {
  margin: 50px 0 10px 0;
  text-align: center;
  color: white;
  font-size: 15px;
}

.App {
  text-align: center;
}

.category-title {
  border-bottom: #fff 1px dashed;
  padding: 5px 0px;
  margin: 10px 0px;
}

.navbar-content {
  color: #fff;
  padding: 5px;
}

.navbar-content-link {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
}

.mySwiper {
  width: 80%;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.navbar-contents {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin: 0px 0px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.navbar-content {
  text-align: center;
  width: 20%;
  font-size: 12px;
  border-radius: 2px;
  color: #fff;
}

.news-title {
  font-size: 25px;
}

.news-description {
  font-size: 15px;
  white-space: pre-line;
}

.news {
  vertical-align: middle;
  background-color: #333;
  margin: 10px 0px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-left: solid 20px rgb(197, 197, 197);
}

.news-category {
}

.news-date-and-category {
  padding: 0 0 0 0;
}

.news-text {
  padding: 0 0 0 0;
}

.news-img {
  margin: auto;
  width: 100%;
  padding: 0 20px 0 0;
}
.news-img img {
  margin: auto;
  width: 100%;
  object-fit: cover;
  height: 90px;
}

.news-category-text {
  text-align: center;
}

.news-date {
}

.news-link {
  text-decoration: none;
  color: #fff;
}

.category-title {
  font-size: 30px;
}

.member-img {
  width: 100%;
}

.member-img img {
  width: 100%;
}

.mamber-name {
  font-size: 25px;
}

.mamber-description {
  padding: 10px 0;
  font-size: 13px;
  white-space: pre-line;
}

.member-details {
  padding: 10px;
}

.release-img {
  width: 100%;
}
.release-img img {
  width: 100%;
}

.release-img-and-title {
  padding: 5px;
}

.release-detail-img {
  padding: 10px;
  width: 100%;
}
.release-detail-img img {
  width: 100%;
}

.release-detail-detail {
  width: 100%;
  padding: 10px;
}

.detail-number-and-title {
  display: flex;
}

.detail-number {
  padding: 0 10px 0 0;
}

.detail-composer {
  margin: 0 0 0 auto;
}

.detail-id-and-release {
  display: flex;
}

.detail-release {
  margin: 0 0 0 auto;
  color: rgb(158, 158, 158);
  font-size: 14px;
}

.detail-id {
  padding-left: 5px;
  width: 30%;
  color: rgb(185, 185, 185);
  background-color: #333333;
  border-left: rgb(82, 82, 82) solid 3px;
  letter-spacing: 2px;
}

.detail-number-and-title {
  border-bottom: solid 0.7px rgb(53, 53, 53);
  padding: 16px 0;
  font-size: 15px;
}

.detail-cd-title {
  font-size: 24px;
  padding-bottom: 4px;
  border-bottom: #666 1px solid;
}

.detail-description {
  width: 100%;
  word-break: break-word;
  font-size: 12px;
  padding: 20px 0;
  white-space: pre-line;
}

.detail-role-and-artist {
  display: flex;
  color: rgb(170, 170, 170);
  font-size: 12px;
  margin-bottom: 8px;
  width: 50%;
}

.detail-artist {
  margin: 0 0 0 auto;
}

.detail-credit-title {
  margin-top: 30px;
}

.shop {
  display: flex;
  flex-wrap: wrap;
}

.shop-row {
  display: flex;
}

.shop-item {
  width: auto;
  text-align: center;
  padding: 10px;
  background-color: #666;
  color: #fff;
}
.shop a {
  color: #fff;
  text-decoration: none;
  width: 100%;
  margin: 10px 0px;
}
.shop-item-none {
  width: 100%;
  text-align: center;
  margin: 10px 5px;
  padding: 5px;
}

.form-item {
  width: 100%;
  margin: 5px 0;
}
.form {
  width: 100%;
}

.form-item input {
  width: 100%;
}
.form-item textarea {
  width: 100%;
  resize: none;
  height: 400px;
}

.form-item button {
  width: 100%;
}
.wall-paper {
  padding: 50px;
}

.wall-paper img {
  width: 100%;
}

.wall-paper {
  color: #fff;
  width: 100%;
}

.wall-paper-item a {
  text-decoration: none;
  color: #fff;
}

.wall-paper-title {
  font-size: 30px;
  border-bottom: #fff solid 1px;
}

.completed-message {
  text-align: center;
}

.about-text {
  font-size: 14px;
}

.wall-paper-text {
  font-size: 14px;
}

.not-found {
  text-align: center;
}

.not-found-text {
  font-size: 30px;
}

.not-found img {
  width: 50%;
}
